<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="1000"
    transition="dialog-bottom-transition"
    v-if="serverDataLoaded"
  >
    <div class="bg-white poppins">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Edit Sku</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <!--begin::Body-->
        <div>
          <div class="row ma-0">
            <div class="h-75px py-0 col-12 col-sm-6" v-if="!isClient">
              <v-select
                v-model="formData.customer_id"
                label="Client"
                item-text="text"
                item-value="index"
                :items="serverData.clients"
                clearable
                outlined
                dense
                :error-messages="customer_idErrors"
                @input="$v.formData.customer_id.$touch()"
                @blur="$v.formData.customer_id.$touch()"
              />
            </div>
            <div class="h-75px py-0 col-12 col-sm-6">
              <v-select
                v-model="formData.sku_type_id"
                label="SKU Type"
                item-text="text"
                item-value="index"
                :items="serverData.sku_types"
                clearable
                outlined
                dense
                :error-messages="sku_type_idErrors"
                @input="$v.formData.sku_type_id.$touch()"
                @blur="$v.formData.sku_type_id.$touch()"
              />
            </div>

            <div
              class="h-75px py-0 col-12 col-sm-6"
              :class="[isClient ? '' : 'col-md-4']"
            >
              <v-text-field
                v-model="formData.system_code"
                label="System Code"
                clearable
                outlined
                dense
                disabled
                :error-messages="system_codeErrors"
                @input="$v.formData.system_code.$touch()"
                @blur="$v.formData.system_code.$touch()"
              />
            </div>
            <div
              class="h-75px py-0 col-12 col-sm-6"
              :class="[isClient ? '' : 'col-md-4']"
            >
              <v-text-field
                v-model="formData.alternative_sku_name"
                label="SKU Label"
                clearable
                outlined
                dense
              />
            </div>
            <div
              class="h-75px py-0 col-12 col-sm-6"
              :class="[isClient ? '' : 'col-md-4']"
            >
              <v-text-field
                v-model="formData.brand"
                label="Brand"
                clearable
                outlined
                dense
              />
            </div>

            <div class="h-75px py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.width"
                label="Width"
                type="Number"
                clearable
                outlined
                dense
                @change="() => validateMinValue('width', 0)"
              />
            </div>
            <div class="h-75px py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.length"
                label="Length"
                type="Number"
                clearable
                outlined
                dense
                @change="() => validateMinValue('length', 0)"
              />
            </div>
            <div class="h-75px py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.height"
                label="Height"
                type="Number"
                clearable
                outlined
                dense
                @change="() => validateMinValue('height', 0)"
              />
            </div>

            <div class="h-75px py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.weight"
                label="Weight"
                type="Number"
                clearable
                outlined
                dense
                @change="() => validateMinValue('weight', 0)"
              />
            </div>
            <div class="h-75px py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.cube"
                label="Cube"
                type="Number"
                clearable
                outlined
                dense
                @change="() => validateMinValue('cube', 0)"
              />
            </div>
            <div class="h-75px py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.hs_code"
                label="HS Code"
                clearable
                outlined
                dense
              />
            </div>

            <div class="h-75px py-0 col-12">
              <v-select
                multiple
                v-model="formData.categories"
                label="Categories"
                item-text="text"
                item-value="index"
                :items="serverData.groups"
                clearable
                outlined
                dense
              />
            </div>

            <div class="h-75px py-0 col-12 col-sm-6 col-md-3">
              <v-select
                v-model="formData.country_id"
                label="Country of origin"
                item-text="text"
                item-value="index"
                :items="serverData.countries"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px py-0 col-12 col-sm-6 col-md-3">
              <v-select
                v-model="formData.currency_id"
                label="Currency"
                item-text="text"
                item-value="index"
                :items="serverData.currencies"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px py-0 col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.price"
                label="Price"
                type="Number"
                clearable
                outlined
                dense
                @change="() => validateMinValue('price', 0)"
              />
            </div>
            <div class="h-75px py-0 col-12 col-sm-6 col-md-3">
              <v-select
                v-model="formData.uom_type_id"
                label="UOM type"
                item-text="text"
                item-value="index"
                :items="serverData.uom_types"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.cost_price"
                label="Cost Price"
                type="Number"
                clearable
                outlined
                dense
                @change="() => validateMinValue('cost_price', 0)"
              />
            </div>
            <div class="h-75px py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.landed_cost"
                label="Landed Cost"
                type="Number"
                clearable
                outlined
                dense
                @change="() => validateMinValue('landed_cost', 0)"
              />
            </div>

            <div class="h-75px py-0 col-12 col-sm-6 col-md-4">
              <v-select
                v-model="formData.dg_type_id"
                label="DG type"
                item-text="text"
                item-value="index"
                :items="serverData.dg_types"
                clearable
                outlined
                dense
              />
            </div>

            <div class="h-75px py-0 col-12">
              <v-text-field
                v-model="formData.dangerous_goods_note"
                label="Dangerous goods note"
                clearable
                outlined
                dense
              />
            </div>

            <div class="h-75px py-0 col-12">
              <v-text-field
                v-model="formData.description"
                label="Description"
                clearable
                outlined
                dense
              />
            </div>

            <div class="py-0 col-12">
              <v-file-input
                v-model="formData.photo"
                show-size
                counter
                multiple
                dense
                outlined
                accept="image/png, image/jpeg, image/bmp"
                prepend-icon="mdi-camera"
                label="Photo"
                clearable
              />
            </div>

            <div
              class="col-12 col-sm-6 col-md-3 h-75px d-flex align-start ma-0 py-0 pl-3"
            >
              <h5 class="pr-4 pt-5 font-weight-regular">Expiry Control</h5>
              <v-switch v-model="formData.expiry_control" hide-details dense />
            </div>

            <div
              class="col-12 col-sm-6 col-md-4 h-75px d-flex align-start ma-0 py-0"
            >
              <h5 class="pr-4 pt-5 font-weight-regular">
                Serial number control
              </h5>
              <v-switch
                v-model="formData.serial_number_control"
                hide-details
                dense
              />
            </div>

            <div
              class="col-12 col-sm-6 col-md-4 h-75px d-flex align-start ma-0 py-0"
            >
              <h5 class="pr-4 pt-5 font-weight-regular">Force split</h5>
              <v-switch
                v-model="formData.is_force_split"
                hide-details
                dense
                :true-value="1"
                :false-value="0"
              ></v-switch>
            </div>

            <div
              class="col-12 col-sm-6 col-md-5 h-75px d-flex align-start ma-0 py-0"
            >
              <h5 class="pr-4 pt-5 font-weight-regular">
                Temperature control checkbox
              </h5>
              <v-switch
                v-model="formData.temperature_control"
                hide-details
                dense
              />
            </div>
          </div>

          <div class="mt-4 pa-0 w-100">
            <SkuDatatable :setSku="setSkus" :codes="formData.codes" />
          </div>
        </div>

        <!--end::Body-->
        <div class="my-4 h-75px d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetEditForm"
          >
            Reset
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitEditForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
import SkuDatatable from "@/own/components/fulfillment/skus/skuAddContainer/SkuDatatable";
import SwalEdited from "@/core/services/AlertServices/SwalEdited";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
export default {
  name: "EditItem",
  mixins: [validationMixin, fieldValueValidation],
  components: {
    SkuDatatable,
  },
  validations: {
    formData: {
      customer_id: {
        required: requiredIf(function () {
          return !this.isClient;
        }),
      },
      system_code: { required },
      sku_type_id: { required },
    },
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      id: null,
      alternative_sku_name: null,
      brand: null,
      categories: [],
      customer_id: null,
      country_id: null,
      currency_id: null,
      cost_price: null,
      landed_cost: null,
      cube: null,
      codes: [],
      description: null,
      expiry_control: 0,
      height: null,
      hs_code: null,
      length: null,
      photo: null,
      price: null,
      serial_number_control: 0,
      sku_type_id: null,
      system_code: null,
      weight: null,
      width: null,
      is_force_split: 0,
      uom_type_id: null,
      dg_type_id: null,
      dangerous_goods_note: null,
      temperature_control: 0,
    },
  }),
  methods: {
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.$store.getters.getItemForAction.id };
      ApiService.post("/fulfillment/skus/edit", data)
        .then((response) => {
          this.serverData = response.data;
          this.formData = response.data.sku;
          this.formData.photo = null;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.loader(false);
        });
    },

    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;

      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    setSkus(item) {
      this.formData.codes = [...item];
    },
    convertToFormData() {
      // console.log(this.formData);
      let data = new FormData();
      Object.entries(this.formData).forEach((item) => {
        if (
          (item[1] && item[0] !== "image" && item[0] !== "photo") ||
          item[1] === 0
        ) {
          if (Array.isArray(item[1])) {
            item[1].forEach((it, index) => {
              if (typeof it === "object" && !Array.isArray(it) && it !== null) {
                Object.entries(it).forEach((el) => {
                  data.append(`${item[0]}[${index}][${el[0]}]`, el[1]);
                });
              } else {
                data.append(`${item[0]}[]`, it);
              }
            });
          } else {
            data.append(item[0], item[1]);
          }
        } else if (item[1] && (item[0] === "image" || item[0] === "photo")) {
          data.append(item[0], item[1][0]);
        }
      });
      return data;
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.pageLoader(true);
      let data = this.convertToFormData();
      ApiService.post("/fulfillment/skus/update", data)
        .then(() => {
          SwalEdited();
          this.toggleModal();
          this.refresher();
          this.pageLoader(false);
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetEditForm() {
      this.$v.$reset();
      this.formData = {
        customer_id: null,
        system_code: null,
        sku_type_id: null,
        alternative_sku_name: null,
        description: null,
        weight: null,
        cube: null,
        length: null,
        width: null,
        height: null,
        brand: null,
        categories: [],
        codes: [],
        expiry_control: 0,
        serial_number_control: 0,
        hs_code: null,
        country_id: null,
        currency_id: null,
        price: null,
        cost_price: null,
        landed_cost: null,
        is_force_split: 0,
        uom_type_id: null,
        dg_type_id: null,
        dangerous_goods_note: null,
        temperature_control: 0,
      };
    },
    toggleModal() {
      if (this.dialog) this.resetEditForm();
      else this.loadDataFromServer();
      this.dialog = !this.dialog;
    },
  },
  computed: {
    isClient() {
      return this.$store.getters.currentUser.data.is_client;
    },
    serverDataLoaded: function () {
      return !!this.serverData;
    },
    customer_idErrors: function () {
      return this.handleFormValidation("customer_id", this);
    },
    system_codeErrors: function () {
      return this.handleFormValidation("system_code", this);
    },
    sku_type_idErrors: function () {
      return this.handleFormValidation("sku_type_id", this);
    },
  },
};
</script>
