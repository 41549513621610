<template>
  <b-dropdown
    ref="action-dropdown"
    id="action-dropdown"
    text="Actions"
    class="mr-3 mb-1 p-0 col-12"
    toggle-class="poppins btn btn--export-filter"
    menu-class="m-0 p-0 ml-n4"
    no-caret
    variant="upload"
  >
    <template v-slot:button-content>
      <div class="px-2">
        <span class="svg-icon">
          <v-icon size="18">mdi-dns</v-icon>
        </span>
        <span>Actions</span>
        <span class="svg-icon mr-0 ml-2">
          <v-icon size="18">mdi-chevron-down</v-icon>
        </span>
      </div>
    </template>
    <div class="q-status-menu py-1 w-175px">
      <div
        class="drop-item poppins px-3 mx-3 rounded-lg cursor-pointer"
        v-for="(action, index) in types"
        :key="index"
        @click="() => handleClick(action.url)"
      >
        <div
          class="py-2 w-100 text-left drop-text poppins font-size-sm font-weight-medium"
          :class="[
            { 'mt-2': index === 0 },
            { 'mb-2': index === types.length - 1 },
          ]"
        >
          {{ action.name }}
        </div>
      </div>
    </div>
  </b-dropdown>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import Swal from "sweetalert2";
import swalEdited from "@/core/services/AlertServices/SwalEdited";

export default {
  name: "ActionsButton",
  props: ["items", "types", "refresher"],
  methods: {
    handleClick(url) {
      if (this.items.length > 0) {
        this.$store.commit(SET_PAGE_LOADING, true);
        let selectedItemsIds = this.items.map((item) => item.id);
        // let data = { [`${this.page}`]: selectedItemsIds };
        let data = { skus: selectedItemsIds };
        ApiService.post(`/fulfillment/${url}`, data)
          .then((response) => {
            if (response.data.hasOwnProperty("error_skus")) {
              // let editedItemMessage = "";
              // response.data.messages.forEach((item) => {
              //   editedItemMessage += `${item}\n`;
              // });

              let editedItemMessage = `<div><ol>`;
              response.data.messages.forEach((item) => {
                editedItemMessage += `<li>${item}</li>`;
              });
              editedItemMessage = editedItemMessage + `</ol></div>`;
              // console.log("editedItemMessage", editedItemMessage);
              Swal.fire({
                title: `Result`,
                html: `${editedItemMessage}`,
                icon: response.data.error_skus === 0 ? "success" : "warning",
                showConfirmButton: true,
              });
            } else swalEdited();
            this.refresher();
          })
          .catch(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      } else {
        Swal.fire({
          title: "Warning",
          text: "Please, select at least one order!",
          icon: "warning",
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: "OK",
          cancelButtonColor: "#FFA800",
        });
      }
    },
  },
};
</script>

<style scoped>
.q-status-menu {
  max-height: 350px;
  overflow-y: scroll;
}
.drop-item {
  font-weight: 500;
  color: rgb(98, 98, 98);
}
.drop-item:hover {
  font-weight: 600;
  background-color: #eee5ff;
  color: #8950fcff !important;
}
.drop-text {
  font-size: 9px;
}
</style>
